<template>
  <div>
    <div class="pc-wrap" style="position: relative">
      <Swiper
        ref="banner"
        class="banner"
        :style="{ height: bannerH + 'px' }"
        :options="bannerOptions"
      >
        <SwiperSlide
          v-if="stars.length"
          class="banner-item banner-earth pc-wrap"
        >
          <div class="pc-content">
            <div class="earth-title phone-hide">
              <span class="mr-20 mark">服务</span>
              <span>合作</span>
              <br />
              <span class="mr-20">共享</span>
              <span class="mark">创新</span>
            </div>
            <div class="earth-title-m phone-show">
              <span class="mr-10 mark">服务</span>
              <span class="mr-10">合作</span>
              <span class="mr-10 mark">共享</span>
              <span>创新</span>
              <div class="earth-subtitle phone-show">品质新生活引领者</div>
            </div>

            <Earth
              ref="earth"
              class="earth-box"
              :boxWidth="boxWidth"
              :dots="stars"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          v-for="(item, index) in banner"
          :key="index"
          class="banner-item img"
          :style="{ 'background-image': 'url(' + item.pic + ')' }"
          @click.native="$jump(item.url)"
        ></SwiperSlide>
      </Swiper>
      <div v-if="bannerSize > 1" class="swiper-pagination">
        <span
          v-for="(item, index) in bannerSize"
          :key="index"
          class="swiper-pagination-item"
          :class="{ on: bannerIndex == index }"
        ></span>
      </div>
    </div>
    <div class="container pc-wrap scroll-mark">
      <div class="pc-content">
        <div class="title">集团介绍</div>
        <div class="subtitle">GROUP INTRODUCTION</div>
        <div class="flex intro">
          <div class="flex-con mr-20">
            <div class="intro-title">关于我们</div>
            <!-- <pre class="content" v-html="group.content"></pre> -->
            <div class="content" v-html="group.content"></div>
            <div
              v-if="group.url"
              class="link phone-hide"
              @click="$jump(group.url)"
            >
              进一步了解 >
            </div>
          </div>
          <div v-if="group.video" class="flex-con">
            <video controls :poster="group.video_img">
              <source :src="group.video" type="video/mp4" />
            </video>
          </div>
          <div
            v-if="group.url"
            class="link phone-show text-c mt-20"
            @click="$jump(group.url)"
          >
            进一步了解 >
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-mark">
      <div class="pc-content">
        <div class="title">润泽汇信鸽福利平台</div>
        <div class="subtitle">WELFARE PLATFORM</div>
        <div class="flex fuli phone-hide">
          <div class="fuli-img">
            <img
              v-if="platform[platformIndex]"
              :src="platform[platformIndex].pic"
              @click="$jump(platform[platformIndex].url)"
            />
          </div>
          <div class="flex-con fuli-btns">
            <div
              v-for="(item, index) in platform"
              :key="index"
              class="fuli-btn hover"
              :class="{ on: platformIndex == index }"
              @click="platformIndex = index"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="fuli-m phone-show clearFix">
          <div
            v-for="(item, index) in platform"
            :key="index"
            class="fuli-m-item"
            @click="$jump(item.url)"
          >
            <img :src="item.h5_pic" alt="" />
            <div class="fuli-m-title fmix-align-sb">
              <span>{{ item.title }}</span>
              <span class="more">进一步了解 ></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-mark">
      <div class="pc-content">
        <div class="title">产品与服务</div>
        <div class="subtitle">PRODUCTS AND SERVICES</div>
        <div class="flex phone-hide">
          <div
            v-for="(item, index) in products"
            :key="index"
            class="flex-con hover pro-item"
            @click="$jump(item.url)"
          >
            <img :src="item.pic" alt="" />
          </div>
        </div>
        <div class="phone-show pro-m">
          <Swiper :options="proSwiperOptions" @click-slide="handleClickSlide">
            <SwiperSlide v-for="(item, index) in products" :key="index">
              <img :src="item.pic" alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-mark">
      <div class="pc-content">
        <div class="title">新闻资讯</div>
        <div class="subtitle">NEWS INFORMATION</div>
        <div class="flex news phone-hide">
          <div
            v-for="(item, index) in article"
            :key="index"
            class="flex-con news-item hover"
            @click="$jump('/news/' + item.id)"
          >
            <div class="news-cate">{{ item.catename }}：</div>
            <div class="news-img">
              <img :src="item.image_input[0]" alt="" />
            </div>
            <div class="news-title ellipsis-1">{{ item.title }}</div>
            <div class="news-content line-2">
              {{ item.synopsis }}
            </div>
            <div class="text-c link">进一步了解 ></div>
          </div>
        </div>
        <div class="phone-show">
          <div
            v-for="(item, index) in article"
            :key="index"
            @click="$jump('/news/' + item.id)"
          >
            <div v-if="index == 0" class="news-item">
              <div class="news-img">
                <img :src="item.image_input[0]" alt="" />
              </div>
              <div class="news-info">
                <div class="fmix-align-sb">
                  <div class="flex-con">
                    <div class="news-title line-1">
                      {{ item.title }}
                    </div>
                  </div>
                  <div class="text-c link">进一步了解 ></div>
                </div>
                <div class="news-content line-2">{{ item.synopsis }}</div>
              </div>
            </div>
            <div v-else class="news-item" @click="$jump('/news/' + item.id)">
              <div class="fmix-align-sb">
                <div class="flex-con">
                  <div class="news-title line-1">
                    {{ item.title }}
                  </div>
                </div>
                <div class="text-c link">进一步了解 ></div>
              </div>
              <div class="news-content line-2">
                {{ item.synopsis }}
              </div>
            </div>
          </div>
          <div class="text-c link" @click="$jump('news/list')">
            更多新闻资讯 >
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap">
      <div class="pc-content">
        <div class="title">股东企业</div>
        <div class="subtitle">SHAREHOLDERS ENTERPRISE</div>
        <div class="flex">
          <div
            v-for="(item, index) in shareholders"
            :key="index"
            class="flex-con company-item"
            @click="$jump(item.url)"
          >
            <img :src="item.banner" alt="" />
          </div>
        </div>

        <div class="partner">
          <div class="title">数字商业联盟合作伙伴</div>
          <div class="subtitle">ALLIANCE PARTNER</div>
          <div class="phone-hide">
            <div class="row">
              <img
                v-for="(item, index) in partner"
                :key="index"
                :src="item.img"
                @click="$jump(item.url)"
                alt=""
              />
            </div>
          </div>

          <div class="phone-show">
            <div class="row">
              <img
                v-for="(item, index) in partner_h5"
                :key="index"
                :src="item.img"
                @click="$jump(item.url)"
                alt=""
              />
            </div>
          </div>
        </div>
        <div v-if="partner_url" class="text-c link" @click="$jump(partner_url)">
          进一步了解 >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Earth from "@/components/earth/index";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
import request from "@/utils/request";

export default {
  components: {
    Earth,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      bannerOptions: {
        on: {
          slideChangeTransitionStart: (e) => {
            this.bannerIndex = e.activeIndex;
          },
        },
      },
      bannerIndex: 0,
      bannerH: 500,
      boxWidth: 375,
      scrollMarks: [0],
      proSwiperOptions: {
        slidesPerView: 2,
        centeredSlides: true,
        loop: true,
      },
      banner: [],
      stars: [],
      group: {},
      platform: [],
      platformIndex: 0,
      article: [],
      partner: [],
      partner_h5: [],
      partner_url: "",
      products: [],
      shareholders: [],
    };
  },
  computed: {
    bannerSize() {
      let size = this.banner.length;
      if (this.stars && this.stars.length) {
        size++;
      }
      return size;
    },
  },
  created() {
    const dom = document.documentElement;
    const winW = dom.clientWidth;
    const winH = dom.clientHeight;
    this.bannerH = winH - 64;
    if (winW > winH) {
      this.boxWidth = Math.min(1100 - 200, winH);
    } else {
      this.boxWidth = winW;
    }

    request.get("index").then((res) => {
      let data = res.data;
      this.banner = data.home_banner;
      this.stars = data.home_star_ring;

      setTimeout(() => {
        this.group = data.home_group_profile[0];
        this.platform = data.home_platform;
        this.products = data.home_products;
        this.article = data.article.list;
        this.shareholders = data.home_shareholder;
        this.partner = data.home_partner;
        this.partner_h5 = data.home_H5_partner;
        if (data.home_partner_link[0]) {
          this.partner_url = data.home_partner_link[0].url;
        }
      }, 300);

      if (this.stars && this.stars.length) {
        this.onVisibilityChange();
      }
    });
  },
  mounted() {},
  methods: {
    setScrollMarks() {
      this.scrollMarks = [0];
      let doms = document.querySelectorAll(".scroll-mark");
      for (var i = 0; i < doms.length; i++) {
        let t = doms[i].offsetTop;
        this.scrollMarks.push(t - 64);
      }
    },
    onVisibilityChange() {
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          this.$refs.earth.earthPause();
        } else {
          this.$refs.earth.earthPlay();
        }
      });
    },
    handleClickSlide(e, index) {
      const { url } = this.products[index];
      url && this.$jump(url);
    },
  },
};
</script>
<style scoped lang="scss">
.banner {
  height: 100vh;
  background: #090d32;
  overflow: hidden;
}
.swiper-pagination {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1;

  .swiper-pagination-item {
    display: inline-block;
    width: 36px;
    height: 6px;
    background: #42404e;
    margin: 10px;

    &.on {
      background: #fff;
    }
  }
}

.banner-item {
  height: 100%;
  position: relative;

  &.img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.banner-earth {
  background: url("~@/assets/images/earth_bg.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
}

.earth-title {
  font-size: 80px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-60%);

  .mark {
    color: #f2ab3f;
  }
}

.earth-title-m {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;

  .mark {
    color: #f2ab3f;
  }

  .earth-subtitle {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 6px;
    margin-top: 10px;
  }
}

.earth-box {
  position: absolute;
  bottom: 0;
  right: -160px;
}

.intro {
  .intro-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .content {
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 2px;
    margin-bottom: 20px;
    width: 100%;
    white-space: pre-wrap;
  }

  video {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.link {
  font-size: 14px;
  color: $link;
  cursor: pointer;
}

.fuli {
  padding: 0 120px;
  .fuli-img {
    flex: 1.8;
    margin-right: 100px;
  }
  .fuli-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .fuli-btn {
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
    padding: 16px 0;
    background: #fff;
    border-radius: 10px;

    &.on,
    &:hover {
      color: $link;
      box-shadow: $hover-shadow;
    }
  }
}

.hover {
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: $hover-shadow;
    position: relative;
    top: -2px;
  }
}

.pro-item {
  margin-left: 10px;
  border-radius: 10px;
  overflow: hidden;

  &:first-of-type {
    margin-left: 0;
  }
}

.news-item {
  margin-left: 20px;
  padding: 20px 15px;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;

  &:first-of-type {
    margin-left: 0;
  }

  .news-cate {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .news-img {
    width: 100%;
    margin-bottom: 20px;
    background: #eee;
    border-radius: 10px;
    overflow: hidden;
  }

  .news-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .news-content {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.company-item {
  margin-left: 100px;

  &:first-of-type {
    margin-left: 0;
  }
}

.partner {
  margin-top: 60px;
  padding-top: 60px;
  border-top: 1px dashed #eee;

  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      width: auto;
      height: 80px;
      margin: 10px 15px;
    }
  }
}
</style>

<style scoped lang="scss">
@media (min-width: 320px) and (max-width: 430px) {
  .banner-earth {
    background-position: -500px 0;
  }
  .earth-box {
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }

  .intro {
    display: block;
    .intro-title {
      display: inline-block;
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 10px;
      padding: 2px 10px;
      color: #fff;
      background: #011167;
      border-radius: 5px;
    }
  }

  .company-item {
    margin: 0 2px;
  }

  .partner {
    .row {
      img {
        height: 50px;
        margin: 5px 0;
      }
    }
  }

  .fuli-m {
    .fuli-m-item {
      float: left;
      width: 49%;
      position: relative;
      margin-right: 2%;
      margin-bottom: 10px;
      background: #ccc;
      border-radius: 6px;
      overflow: hidden;

      &:nth-child(2n) {
        margin-right: 0;
      }

      img {
        display: block;
        widows: 100%;
      }

      .fuli-m-title {
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 10px;
        font-size: 16px;
        color: #fff;
        background: #011167;
        box-sizing: border-box;

        .more {
          font-size: 12px;
        }
      }
    }
  }

  .pro-m {
    .swiper-slide {
      transition: 300ms;
      transform: scale(0.9);
      border-radius: 10px;
      overflow: hidden;
    }

    .swiper-slide-active {
      transform: scale(1);
      box-shadow: $hover-shadow;
    }
  }

  .news-item {
    padding: 0;
    margin-bottom: 30px;
    background: none;

    .news-img {
      border-radius: 0;
      background: #999;
      margin-bottom: 0;
    }
    .news-info {
      background: #fff;
      padding: 10px 10px 1px;
    }

    .news-title {
      margin-bottom: 0;
      color: #011168;
    }

    .news-content {
      margin-top: 10px;
    }
  }
}
</style>